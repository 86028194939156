import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKINS_PORT}${process.env.VUE_APP_API_PATH}/checkins`;
const urlBase = `${api}/credenciamentos`;

export default {

    pesquisarParticipante(cpf, eventoId) {
        return axiosJwt.get(`${urlBase}/pesquisar/${cpf}${eventoId ? `?eventoId=${eventoId}` : ''}`);
    },

    emitirEtiqueta(participante) {
        return axiosJwt.post(`${urlBase}/etiqueta`, participante, {
            responseType: 'blob',
        });
    },

    gerarQrCode(participante) {
        return axiosJwt.post(`${urlBase}/qrcode`, participante);
    },

    obterImagemCredenciamento(eventoId) {
        return axiosJwt.get(`${urlBase}/imagem/${eventoId}`);
    },
};